import { useMsal } from '@azure/msal-react'
import { useState, useEffect } from 'react'
import { InteractionStatus } from '@azure/msal-browser'
import { Button, Message } from '@bdo/kitchensink'
import clsx from 'clsx'
import { useIntl } from 'react-intl'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import Icon from 'ui-components/Icon/Icon'
import styles from './HeaderButtons.module.scss'
import { getUserProfileData, UserProfileData } from '../../services/ms-graph.service'

export default function HeaderButtons() {
  const { instance, inProgress } = useMsal()
  const intl = useIntl()
  const [graphData, setGraphData] = useState<undefined | UserProfileData>(undefined)

  useEffect(() => {
    if (!graphData && inProgress === InteractionStatus.None) {
      getUserProfileData()
        .then((data) => {
          setGraphData(data)
        })
        .catch(async (error) => {
          LoggerHelper(error)
          await Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }), 5)
        })
    }
  }, [inProgress, graphData, instance])

  const signOut = async () => {
    await instance.logoutPopup({
      mainWindowRedirectUri: '/'
    })
  }

  return (
    <div className='flex alignItems'>
      <div className={clsx(styles.userData, 'flex', 'alignItems')}>
        <Icon type='Contact' className={styles.userIcon} />
        <p>{graphData?.displayName}</p>
      </div>
      <Button shape='circle' onClick={signOut}>
        <Icon type='Leave' className={styles.buttonIcon} />
      </Button>
    </div>
  )
}
