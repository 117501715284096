import { Message, Skeleton } from '@bdo/kitchensink'
import translate from 'i18n/translate'
import clsx from 'clsx'
import { Link, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { getProjects } from 'services/portalsAndProjects.service'
import { GlobalPortalProject } from 'services/Client/client.models'
import { AxiosError } from 'axios'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import { from } from 'linq-to-typescript'
import styles from './infoBox.module.scss'

type Props = {
  isPortalCreated: boolean
  isProjectLoading: boolean
  setIsProjectLoading: (value: boolean) => void
}

function ProjectInfo(props: Readonly<Props>) {
  const { isPortalCreated, isProjectLoading, setIsProjectLoading } = props
  const { id } = useParams<string>()
  const clientId = parseInt(id ?? '0', 10)
  const intl = useIntl()
  const [lastCreatedProjectName, setLastCreatedProjectName] = useState<string>('')

  useEffect(() => {
    if (!isPortalCreated) {
      return
    }
    getAllProjects()
  }, [isPortalCreated])

  function getAllProjects() {
    getProjects(clientId)
      .then((projects) => {
        if (projects.length > 0) {
          const lastProjectName = getLatestProjectName(projects)
          setLastCreatedProjectName(lastProjectName ?? '')
        } else {
          Message.warning(intl.$t({ id: 'api_projects_do_not_exist' }), 5)
        }
      })
      .catch((error: AxiosError | Error) => {
        Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }))
        LoggerHelper(error)
      })
      .finally(() => {
        setIsProjectLoading(false)
      })
  }

  function getLatestProjectName(projects: GlobalPortalProject[]) {
    const result = from(projects)
      .orderByDescending((x) => x.projectCreated)
      .firstOrDefault()?.projectName

    return result
  }

  return (
    <div className={styles.mainInfoWrapper}>
      <div className={styles.boxLabel}>{translate('last_created_project_info')}</div>
      {isProjectLoading ? (
        <Skeleton active title={{ width: 300 }} paragraph={false} />
      ) : (
        <div className={clsx(styles.infoWrapper, 'flex', 'alignItems')}>
          <span className={styles.info}>{translate('project_name')}:</span>
          <div className={styles.editInfo}>
            <span>{lastCreatedProjectName !== '' ? lastCreatedProjectName : '-'}</span>
          </div>
        </div>
      )}
      <Link to='../portal_and_projects' className={clsx('textButton', styles.projectsLink)}>
        {translate('projects_view_all')}
      </Link>
    </div>
  )
}

export default ProjectInfo
