import { notification } from 'antd'
import { useEffect } from 'react'
import Icon from 'ui-components/Icon/Icon'

export enum NotificationType {
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Error = 'error'
}

export type AsyncMessageType = {
  id: string
  title: string
  description: string
  status: NotificationType
  data: { clientId: number; appId: number; instanceId: string }
}

type Props = {
  message: AsyncMessageType
  cleanUpMessageOnClose: (id: string) => void
}

function AsyncNotification(props: Readonly<Props>) {
  const { message, cleanUpMessageOnClose } = props
  const [api, contextHolder] = notification.useNotification()

  const openNotification = (type: NotificationType) => {
    api[type]({
      message: message.title,
      description: message.description,
      duration: 0,
      placement: 'topRight',
      onClose: () => cleanUpMessageOnClose(message.id),
      icon: getIcon(message.status),
      closeIcon: <Icon type='ChromeClose' style={{ fontSize: '12px', cursor: 'pointer' }} data-testid='close-icon' />,
      style: {
        width: 450
      }
    })
  }

  useEffect(() => {
    if (!message) {
      return
    }
    setTimeout(() => {
      openNotification(message.status)
    }, 2000)
  }, [message])

  function getIcon(messageStatus: string) {
    switch (messageStatus) {
      case 'success':
        return <Icon type='CompletedSolid' style={{ color: '#54a575' }} />

      case 'info':
        return <Icon type='InfoSolid' style={{ color: '#6f6f6f' }} />

      case 'warning':
        return <Icon type='AlertSolid' style={{ color: '#fd7e14' }} />

      default:
        return <Icon type='AlertSolid' style={{ color: '#ed1a3b' }} />
    }
  }

  return <div>{contextHolder}</div>
}

export default AsyncNotification
