import { from } from 'linq-to-typescript'
import { AnswersInfo, ClientDto, LastChange } from 'services/Client/client.models'
import { ClientQuestionnaireProcessingStatus } from 'services/Client/clientProcessedQuestionnaireStatus.service'
import { Apps, Services } from 'services/Questionnaire/questionnaire.models'
import { AnswerQuestionnaireStatus } from 'services/QuestionsAnswers/QuestionsAnswers.model'
import create from 'zustand'
import { devtools } from 'zustand/middleware'

type ClientState = {
  client: ClientDto
  appIdsWhereAnswersForImportAreAvailable: number[]
  appsThatAreSendingRequest: { clientId: number; appId: number; instanceId: string }[]
  currentInstanceData: AnswersInfo
}

type StoreType = ClientState | Partial<ClientState> | ((state: ClientState) => ClientState | Partial<ClientState>)

type ClientActions = {
  setClient: (client: ClientDto) => void
  updateStatusOfSelectedPublishedQuestionnaire: (status: AnswerQuestionnaireStatus, appId: number) => void
  addAppIdsThatHaveAnswersAvailable: (appId: number) => void
  removeAppIdsThatHaveAnswersAvailable: (appId: number) => void
  addIdOfAppSendingRequest: (clientId: number, appId: number, instanceId: string) => void
  removeIdOfAppWhenRequestSent: (clientId: number, appId: number, instanceId: string) => void
  updateCurrentInstanceData: (currentInstanceData: AnswersInfo) => void
  updateStatusOfCurrentInstance: (status: AnswerQuestionnaireStatus) => void
  updatecurrentInstanceLastExport: (lastExport: LastChange) => void
  reset: () => void
}

const initialState: ClientState = {
  client: {
    id: 0,
    name: '',
    legalForm: '',
    address: '',
    sbi: {
      id: '',
      description: ''
    },
    postalCode: '',
    country: '',
    serviceApps: [
      {
        id: 1,
        service: Services.outsourcing,
        application: Apps.twinfield,
        publishedQuestionnaire: {
          questionnaireId: '',
          questionnaireName: '',
          status: AnswerQuestionnaireStatus.NotStarted,
          lastExport: { date: new Date(), user: { firstName: '', id: '', lastName: '' } },
          lastModification: { date: new Date(), user: { firstName: '', id: '', lastName: '' } },
          instanceId: ''
        },
        lastAnsweredQuestionnaire: {
          questionnaireId: '',
          questionnaireName: '',
          status: AnswerQuestionnaireStatus.NotStarted,
          lastExport: { date: new Date(), user: { firstName: '', id: '', lastName: '' } },
          lastModification: { date: new Date(), user: { firstName: '', id: '', lastName: '' } },
          instanceId: ''
        }
      },
      {
        id: 2,
        service: Services.globalPortal,
        application: Apps.createPortal,
        publishedQuestionnaire: {
          questionnaireId: '',
          questionnaireName: '',
          status: AnswerQuestionnaireStatus.NotStarted,
          lastExport: { date: new Date(), user: { firstName: '', id: '', lastName: '' } },
          lastModification: { date: new Date(), user: { firstName: '', id: '', lastName: '' } },
          instanceId: ''
        },
        lastAnsweredQuestionnaire: {
          questionnaireId: '',
          questionnaireName: '',
          status: AnswerQuestionnaireStatus.NotStarted,
          lastExport: { date: new Date(), user: { firstName: '', id: '', lastName: '' } },
          lastModification: { date: new Date(), user: { firstName: '', id: '', lastName: '' } },
          instanceId: ''
        }
      },
      {
        id: 3,
        service: Services.globalPortal,
        application: Apps.createProject,
        publishedQuestionnaire: {
          questionnaireId: '',
          questionnaireName: '',
          status: AnswerQuestionnaireStatus.NotStarted,
          lastExport: { date: new Date(), user: { firstName: '', id: '', lastName: '' } },
          lastModification: { date: new Date(), user: { firstName: '', id: '', lastName: '' } },
          instanceId: ''
        },
        lastAnsweredQuestionnaire: {
          questionnaireId: '',
          questionnaireName: '',
          status: AnswerQuestionnaireStatus.NotStarted,
          lastExport: { date: new Date(), user: { firstName: '', id: '', lastName: '' } },
          lastModification: { date: new Date(), user: { firstName: '', id: '', lastName: '' } },
          instanceId: ''
        }
      }
    ]
  },
  appIdsWhereAnswersForImportAreAvailable: [],
  appsThatAreSendingRequest: [],
  currentInstanceData: {
    questionnaireId: '',
    questionnaireName: '',
    status: AnswerQuestionnaireStatus.NotStarted,
    lastExport: { date: new Date(), user: { firstName: '', id: '', lastName: '' } },
    lastModification: { date: new Date(), user: { firstName: '', id: '', lastName: '' } },
    instanceId: ''
  }
}

const useClientStore = create<ClientState & ClientActions, [['zustand/devtools', ClientState & ClientActions]]>(
  devtools(
    (set) => ({
      ...initialState,
      setClient: (client) => set(setClient(client), false, 'Set Client'),
      updateStatusOfSelectedPublishedQuestionnaire: (status, appId) =>
        set(updateStatusOfSelectedPublishedQuestionnaire(status, appId), false, 'Update status of seleced published questionnaire'),
      addAppIdsThatHaveAnswersAvailable: (appId) => set(addIdOfAppThatHaveAnswersAvailable(appId), false, 'Update id of app that has answers available'),
      removeAppIdsThatHaveAnswersAvailable: (appId) => set(removeIdOfAppThatHaveAnswersAvailable(appId), false, 'Update id of app that has answers available'),
      addIdOfAppSendingRequest: (clientId, appId, instanceId) => set(addIdOfAppSendingRequest(clientId, appId, instanceId), false, 'Update id of app that is sending request'),
      removeIdOfAppWhenRequestSent: (clientId, appId, instanceId) =>
        set(removeIdOfAppWhenRequestSent(clientId, appId, instanceId), false, 'Remove id of app that finished sending request'),
      updateCurrentInstanceData: (currentInstanceData) => set(updateCurrentInstanceData(currentInstanceData), false, 'Update current instance data'),
      updateStatusOfCurrentInstance: (status) => set(updateStatusOfCurrentInstance(status), false, 'Update current instance status'),
      updatecurrentInstanceLastExport: (lastExport) => set(updatecurrentInstanceLastExport(lastExport), false, 'Update last instance export data'),
      reset: () => {
        set((state) => ({ ...state, ...initialState }), false, 'Reset store')
      }
    }),
    { name: 'Onboarding tool - Client store' }
  )
)

export const useClient = () => useClientStore((state) => state.client)
export const useClientId = () => useClientStore((state) => state.client.id)
export const useClientRelationNumber = () => useClientStore((state) => state.client.id)
export const useClientName = () => useClientStore((state) => state.client.name)
export const useClientAddress = () => useClientStore((state) => state.client.address)
export const useClientPostCode = () => useClientStore((state) => state.client.postalCode)
export const useClientCountry = () => useClientStore((state) => state.client.country)
export const useClientLegalForm = () => useClientStore((state) => state.client.legalForm)
export const useClientSbi = () => useClientStore((state) => state.client.sbi)

export const useServices = () =>
  useClientStore((state) => {
    const services = from(state.client.serviceApps)
      .groupBy((x) => x.service)
      .select((x) => x.toArray())
      .toArray()

    if (!services) {
      throw new Error('Cannot find any service')
    }

    return services
  })

export const useServiceByAppId = (appId: number) =>
  useClientStore((state) => {
    const service = state.client.serviceApps.find((item) => item.id === appId)?.service

    if (!service) {
      throw new Error('Cannot find service by name')
    }

    return service
  })

export const useApplicationByName = (appName: string) =>
  useClientStore((state) => {
    const application = state.client.serviceApps.find((item) => item.application === appName)

    if (!application) {
      throw new Error('Cannot find application by name')
    }

    return application
  })

export const useApplicationIdByName = (appName: string) =>
  useClientStore((state) => {
    const application = state.client.serviceApps.find((item) => item.application === appName)

    if (!application) {
      throw new Error('Cannot find application by name')
    }

    return application.id
  })

export const useApplicationById = (appId: number) =>
  useClientStore((state) => {
    const application = state.client.serviceApps.find((item) => item.id === appId)
    if (!application) {
      throw new Error('Cannot find application by id')
    }

    return application
  })

export const useApplicationName = (appId: number) =>
  useClientStore((state) => {
    const application = state.client.serviceApps.find((item) => item.id === appId)
    if (!application) {
      throw new Error('Cannot find application by id')
    }

    return application.application
  })

export const usePublishedQuestionnaireData = (appId: number) =>
  useClientStore((state) => {
    const application = state.client.serviceApps.find((item) => item.id === appId)

    if (!application) {
      throw new Error('Cannot find application to display published questionnaire data')
    }

    return application.publishedQuestionnaire
  })

export const useLastAnsweredQuestionnaireData = (appId: number) =>
  useClientStore((state) => {
    const application = state.client.serviceApps.find((item) => item.id === appId)

    if (!application) {
      throw new Error('Cannot find application to display published questionnaire data')
    }

    return application.lastAnsweredQuestionnaire
  })

export const useStatusOfCurrentPublishedQuestionnaire = (appId: number) =>
  useClientStore((state) => {
    const applicationWithQuestionnaire = state.client.serviceApps.find((app) => app.id === appId)

    if (!applicationWithQuestionnaire) {
      throw new Error('Cannot find status for given questionnaire id')
    }

    return applicationWithQuestionnaire.publishedQuestionnaire.status
  })

export const useStatusOfCurrentInstance = () => useClientStore((state) => state.currentInstanceData.status)
export const useLastExportOfCurrentInstance = () => useClientStore((state) => state.currentInstanceData.lastExport)

function setClient(client: ClientDto): StoreType {
  return (state) => {
    const newClient = { ...state, client }
    return newClient
  }
}

function updateStatusOfSelectedPublishedQuestionnaire(status: AnswerQuestionnaireStatus, appId: number): StoreType {
  return (state) => {
    const applications = state.client.serviceApps.map((item) => {
      if (item.id === appId) {
        return { ...item, publishedQuestionnaire: { ...item.publishedQuestionnaire, status } }
      }
      return item
    })

    if (!applications) {
      throw Error('There is no such application')
    }

    const client = { ...state.client, serviceApps: applications }
    const newState = { ...state, client }
    return newState
  }
}

function addIdOfAppThatHaveAnswersAvailable(appId: number): StoreType {
  return (state) => {
    if (!state.appIdsWhereAnswersForImportAreAvailable.includes(appId)) {
      state.appIdsWhereAnswersForImportAreAvailable.push(appId)
    }

    return { ...state }
  }
}

function removeIdOfAppThatHaveAnswersAvailable(appId: number): StoreType {
  return (state) => {
    const appIdsArray = state.appIdsWhereAnswersForImportAreAvailable.filter((item) => item !== appId)

    return { ...state, appIdsWhereAnswersForImportAreAvailable: appIdsArray }
  }
}

function addIdOfAppSendingRequest(clientId: number, appId: number, instanceId: string): StoreType {
  return (state) => {
    const newApp = { clientId, appId, instanceId }
    const isAlreadyIn = state.appsThatAreSendingRequest.find((app) => newApp.clientId === app.clientId && newApp.appId === app.appId && newApp.instanceId === app.instanceId)

    return { ...state, appsThatAreSendingRequest: isAlreadyIn ? state.appsThatAreSendingRequest : [...state.appsThatAreSendingRequest, { ...newApp }] }
  }
}

function removeIdOfAppWhenRequestSent(clientId: number, appId: number, instanceId: string): StoreType {
  return (state) => {
    const appIdsArray = state.appsThatAreSendingRequest.filter((item) => isItemWithFinishedExport(item, clientId, appId, instanceId))
    return { ...state, appsThatAreSendingRequest: appIdsArray }
  }
}

function isItemWithFinishedExport(item: ClientQuestionnaireProcessingStatus, clientId: number, appId: number, instanceId: string) {
  if (clientId === item.clientId && appId === item.appId && instanceId === item.instanceId) {
    return clientId !== item.clientId && appId !== item.appId && instanceId !== item.instanceId
  }

  return true
}

function updateCurrentInstanceData(currentInstanceData: AnswersInfo): StoreType {
  return (state) => ({ ...state, currentInstanceData })
}

function updateStatusOfCurrentInstance(status: AnswerQuestionnaireStatus): StoreType {
  return (state) => {
    const currentInstance = { ...state.currentInstanceData, status }
    const newState = { ...state, currentInstanceData: currentInstance }

    return newState
  }
}

function updatecurrentInstanceLastExport(lastExport: LastChange): StoreType {
  return (state) => {
    const currentInstance = { ...state.currentInstanceData, lastExport }
    const newState = { ...state, currentInstanceData: currentInstance }

    return newState
  }
}

export default useClientStore
