/* eslint-disable @typescript-eslint/no-use-before-define */
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import TopBar from 'ui-components/TopBar/TopBar'
import { useIntl } from 'react-intl'
import { getQuestionnaire } from 'services/Questionnaire/questionnaire.service'
import useClientStore, { useApplicationName, useClientName, useStatusOfCurrentInstance, useStatusOfCurrentPublishedQuestionnaire } from 'features/clients/clientZustandStore'
import { toLimitString } from 'utils/StringHelper/string-helper'
import GroupLayout from 'features/clientDetails/components/GroupLayout/GroupLayout'
import GroupTabsAnswers from 'features/clientDetails/components/GroupTabsAnswers/GroupTabsAnswers'
import useQuestionnaireAnswerStore, { useQuestionnaireName, useIsDirty, useQuestionnaireHasErrors } from 'features/clientDetails/components/questionAnswerZustandStore'
import SaveAndComplete from 'features/clientDetails/components/SaveAndComplete/SaveAndComplete'
import { getInstanceInfoById, getAnswersForClient } from 'services/QuestionsAnswers/QuestionAnswer.service'
import EmptyPage from 'ui-components/EmptyPage/EmptyPage'
import { Message } from '@bdo/kitchensink'
import { getClient } from 'services/Client/client.service'
import { AnswerQuestionnaireStatus } from 'services/QuestionsAnswers/QuestionsAnswers.model'
import usePrompt from 'hooks/usePromptHook'
import Loader from 'ui-components/Loader/Loader'
import { useErrorBoundary } from 'react-error-boundary'
import { isProcessing } from 'services/Client/clientProcessedQuestionnaireStatus.service'
import PageNotification from 'ui-components/PageNotification/PageNotification'
import { appsConfiguration } from 'configuration/applicationsConfiguration'
import styles from './QuestionnaireFill.module.scss'

function QuestionnaireFill() {
  const location = useLocation()
  const currentProjectInstanceStatus = useStatusOfCurrentInstance()
  const setQuestionnaireVM = useQuestionnaireAnswerStore((state) => state.setQuestionnaireVM)
  const reset = useQuestionnaireAnswerStore((state) => state.reset)
  const selectedGroupKey = useQuestionnaireAnswerStore((state) => state.selectedGroupKey)
  const selectGroup = useQuestionnaireAnswerStore((state) => state.selectGroup)
  const updateAnswers = useQuestionnaireAnswerStore((state) => state.updateAnswers)
  const questionnaireName = useQuestionnaireName()
  const isDirty = useIsDirty()
  const setIsDirty = useQuestionnaireAnswerStore((state) => state.setIsDirty)
  const updateCurrentInstanceData = useClientStore((state) => state.updateCurrentInstanceData)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const intl = useIntl()
  usePrompt(intl.$t({ id: 'user_message_unsaved_changes' }), isDirty)
  const setClient = useClientStore((state) => state.setClient)
  const updateStatusOfCurrentInstance = useClientStore((state) => state.updateStatusOfCurrentInstance)
  const { questionnaireId, id, appId, instanceId } = useParams<string>()
  const clientId = parseInt(id ?? '0', 10)
  const applicationId = parseInt(appId ?? '0', 10)
  const applicationName = useApplicationName(applicationId)
  const clientName = useClientName()
  const formStatus = useStatusOfCurrentPublishedQuestionnaire(applicationId)
  const questionnaireHasErrors = useQuestionnaireHasErrors()
  const { showBoundary } = useErrorBoundary()
  const { isAnswersImportEnabled, isReadOnlyAfterCompletion } = appsConfiguration[applicationName]
  const [areAnswersFromImport, setAreAnswersFromImport] = useState(false)

  useEffect(() => {
    if (!clientId || !questionnaireId || !applicationId || !instanceId) {
      return
    }
    setIsLoading(true)
    const getAnswersCallPromise = getAnswersForClient(clientId, instanceId)
    const getClientPromise = getClient(clientId)
    const getCurrentInstance = getInstanceInfoById(instanceId)

    Promise.all([getAnswersCallPromise, getClientPromise, getCurrentInstance])
      .then(([answersData, clientData, currentInstanceData]) => {
        setClient(clientData)

        const areAnswersImported = answersData.some((a) => a.id === null)
        setAreAnswersFromImport(areAnswersImported)

        if (areAnswersImported && !isAnswersImportEnabled) {
          updateAnswers([])
        } else {
          updateAnswers(answersData)
        }

        if (isAnswersImportEnabled && areAnswersImported) {
          Message.success(intl.$t({ id: 'user_message_answers_imported' }), 5)
          updateStatus()
        }

        if (isProcessing(clientId, applicationId, instanceId)) {
          updateCurrentInstanceData({ ...currentInstanceData, status: AnswerQuestionnaireStatus.Processing })
        } else {
          updateCurrentInstanceData(currentInstanceData)
        }

        return areAnswersImported
      })
      .then((areAnswersImported) => getQuestionnaireCall(areAnswersImported))
      .catch((e) => {
        if (isAnswersImportEnabled && areAnswersFromImport) {
          Message.error(intl.$t({ id: 'user_message_answers_imported_error' }), 5)
        }
        showBoundary(e)
      })
      .finally(() => {
        setIsLoading(false)
      })

    // eslint-disable-next-line consistent-return
    return function cleanupStore() {
      reset()
    }
  }, [])

  useEffect(() => {
    updateStatus()
  }, [questionnaireHasErrors, isDirty])

  const updateStatus = () => {
    if (!instanceId) {
      return
    }

    if (isProcessing(clientId, applicationId, instanceId)) {
      updateStatusOfCurrentInstance(AnswerQuestionnaireStatus.Processing)
    } else if ((formStatus !== AnswerQuestionnaireStatus.Completed && formStatus !== AnswerQuestionnaireStatus.Processing) || isDirty) {
      if (questionnaireHasErrors) {
        updateStatusOfCurrentInstance(AnswerQuestionnaireStatus.InProgress)
      } else {
        updateStatusOfCurrentInstance(AnswerQuestionnaireStatus.Ready)
      }
    }
  }

  const getQuestionnaireCall = (areAnswersImported: boolean) => {
    if (!questionnaireId) {
      return Promise.reject(new Error('id questionnaire param is missing'))
    }
    return getQuestionnaire(questionnaireId)
      .then((data) => {
        setQuestionnaireVM(data)
        if (data.groups.length > 0) {
          selectGroup(data.groups[0].key)
        }
      })
      .catch((e) => {
        showBoundary(e)
      })
      .finally(() => setIsDirty(areAnswersImported && isAnswersImportEnabled))
  }

  return (
    <div className={styles.wrapper}>
      {!isAnswersImportEnabled || !areAnswersFromImport ? (
        <Loader open={isLoading} text={intl.$t({ id: 'action_loading' })} />
      ) : (
        <Loader open={isLoading} text={intl.$t({ id: 'action_loading_answers' })} />
      )}
      {!isLoading && (
        <>
          <TopBar
            topBarData={{
              pageName: `${toLimitString(questionnaireName, 40)}`,
              icon: 'QuestionnaireMirrored',
              backLink: location.state.key === 'portal_and_projects' ? `/clients/${clientId}/portal_and_projects` : `/clients/${clientId}/services_apps`,
              backLinkPageNameTranslation: location.state.key === 'portal_and_projects' ? intl.$t({ id: 'portal_and_projects' }) : intl.$t({ id: 'services_and_apps' }),
              info1: `${clientId}`,
              info2: `${toLimitString(clientName, 100)}`,
              info3: intl.$t({ id: `application_${applicationName}` }),
              notification:
                currentProjectInstanceStatus === AnswerQuestionnaireStatus.Completed && isReadOnlyAfterCompletion ? (
                  <PageNotification infoNotification notification={intl.$t({ id: 'user_message_read_only_questionnaire' })} />
                ) : undefined
            }}
          >
            <SaveAndComplete appId={applicationId} />
          </TopBar>
          <div className={`${styles.contentWrapper} flex`}>
            <div className={styles.questionGroupsWrapper}>
              <GroupTabsAnswers />
            </div>
            <div className={styles.questionsWrapper}>{selectedGroupKey ? <GroupLayout /> : <EmptyPage />}</div>
          </div>
        </>
      )}
    </div>
  )
}

export default QuestionnaireFill
