import { useEffect, useState } from 'react'
import { Message, Table } from '@bdo/kitchensink'
import translate from 'i18n/translate'
import TableEmptyState from 'ui-components/TableEmptyState/TableEmptyState'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import { useIntl } from 'react-intl'
import { GlobalPortalProject, LastUpdate } from 'services/Client/client.models'
import moment from 'moment'
import { getProjects } from 'services/portalsAndProjects.service'
import { useClientId } from 'features/clients/clientZustandStore'
import { Apps } from 'services/Questionnaire/questionnaire.models'
import { AnswerQuestionnaireStatus } from 'services/QuestionsAnswers/QuestionsAnswers.model'
import { isProcessing } from 'services/Client/clientProcessedQuestionnaireStatus.service'
import RedirectButton from './RedirectButton'
import AnswersStatusBadge from './AnswersStatusBadge'

export default function ProjectsList() {
  const intl = useIntl()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const clientId = useClientId()
  const [projects, setProjects] = useState<GlobalPortalProject[]>([])

  useEffect(() => {
    fetchProjects()
  }, [])

  const fetchProjects = () => {
    if (!clientId) {
      return
    }
    setIsLoading(true)

    getProjects(clientId)
      .then((gpProjects: GlobalPortalProject[]) => {
        const sortedProjects = [...gpProjects].reverse()
        setProjects(sortedProjects)
        if (gpProjects.length === 0) {
          Message.warning(intl.$t({ id: 'api_projects_do_not_exist' }), 5)
        }
      })
      .catch((error) => {
        LoggerHelper(error)
        Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }))
      })
      .finally(() => setIsLoading(false))
  }

  const columns = [
    {
      title: translate('project_name'),
      dataIndex: 'projectName',
      key: 'projectName',
      width: '19%'
    },
    {
      title: translate('questionnaire_name'),
      dataIndex: 'questionnaireName',
      key: 'questionnaireName',
      width: '19%',
      render: (questionnaireName: string) => questionnaireName ?? '-'
    },
    {
      title: translate('table_column_status'),
      dataIndex: 'status',
      key: 'status',
      width: '19%',
      render: (status: AnswerQuestionnaireStatus, project: GlobalPortalProject) => {
        if (isProcessing(clientId, project.appId, project.instanceId)) {
          return <AnswersStatusBadge questionnaireStatus={AnswerQuestionnaireStatus.Processing} applicationName={Apps.createProject} />
        }
        return status ? <AnswersStatusBadge questionnaireStatus={status} applicationName={Apps.createProject} /> : '-'
      }
    },
    {
      title: translate('table_column_creation_date'),
      dataIndex: 'projectCreated',
      key: 'projectCreated',
      width: '19%',
      render: (projectCreated: Date | null) => (projectCreated !== null ? moment.utc(projectCreated).local().format('DD.MM.YYYY HH:mm') : '-')
    },
    {
      title: translate('table_column_created_by'),
      dataIndex: 'createdBy',
      width: '19%',
      key: 'createdBy',
      render: (createdBy: LastUpdate) => (createdBy !== null ? `${createdBy.user.firstName} ${createdBy.user.lastName}` : '-')
    },
    {
      title: translate('actions'),
      dataIndex: 'action',
      key: 'action',
      width: '5%',
      render: (action: boolean, project: GlobalPortalProject) => (
        <span>{action && <RedirectButton instanceId={project.instanceId} questionnaireId={project.questionnaireId} appName={Apps.createProject} />}</span>
      )
    }
  ]

  return (
    <Table
      dataSource={projects}
      columns={columns}
      rowKey='projectName'
      loading={isLoading}
      locale={{
        emptyText: <TableEmptyState />
      }}
      data-testid='projects_table'
      id='ClientTable'
    />
  )
}
