import { Apps, Services } from '../services/Questionnaire/questionnaire.models'

// eslint-disable-next-line import/prefer-default-export
export const servicesAndApps = [
  { id: 1, serviceName: Services.outsourcing, apps: [{ id: 1, appName: Apps.twinfield }] },
  {
    id: 2,
    serviceName: Services.globalPortal,
    apps: [
      { id: 1, appName: Apps.createPortal },
      { id: 2, appName: Apps.createProject }
    ]
  }
]
