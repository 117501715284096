import translate from 'i18n/translate'
import clsx from 'clsx'
import { AnswerQuestionnaireStatus } from 'services/QuestionsAnswers/QuestionsAnswers.model'
import { AnswersInfo } from 'services/Client/client.models'
import moment from 'moment'
import styles from './infoBox.module.scss'

type Props = {
  modificationInfo: AnswersInfo
  portalName: string | undefined
}

function PortalInfo(props: Readonly<Props>) {
  const { modificationInfo, portalName } = props

  return (
    <div className={styles.mainInfoWrapper}>
      <div className={styles.boxLabel}>{portalName ? translate('created_portal_info') : translate('portal_in_creation')}</div>

      <div className={clsx(styles.infoWrapper, 'flex', 'alignItems')}>
        <span className={styles.info}>{translate('portal_last_created')}</span>
        <div className={styles.editInfo}>
          <span>{portalName}</span>
        </div>
      </div>

      {modificationInfo && (modificationInfo.status === AnswerQuestionnaireStatus.InProgress || modificationInfo.status === AnswerQuestionnaireStatus.Ready) && (
        <div className={clsx(styles.infoWrapper, 'flex', 'alignItems')}>
          <span className={styles.info}>{translate('general_edited_by')}</span>
          {modificationInfo.lastModification && (
            <div className={styles.editInfo}>
              <span>{`${modificationInfo.lastModification.user.firstName} ${modificationInfo.lastModification.user.lastName}`}</span>
              <span>{moment.utc(modificationInfo.lastModification.date).local().format('DD-MM-YYYY HH:mm')}</span>
            </div>
          )}
        </div>
      )}

      {modificationInfo && modificationInfo.status === AnswerQuestionnaireStatus.Completed && (
        <div className={clsx(styles.infoWrapper, 'flex', 'alignItems')}>
          <span className={styles.info}>{translate('general_completed_by')}</span>
          {modificationInfo.lastExport && (
            <div className={styles.editInfo}>
              <span>{`${modificationInfo.lastExport.user.firstName} ${modificationInfo.lastExport.user.lastName}`}</span>
              <span>{moment.utc(modificationInfo.lastExport.date).local().format('DD-MM-YYYY HH:mm')}</span>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default PortalInfo
