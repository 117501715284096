import { useEffect, useState } from 'react'
import { Message, Table } from '@bdo/kitchensink'
import translate from 'i18n/translate'
import TableEmptyState from 'ui-components/TableEmptyState/TableEmptyState'
import { useClientId } from 'features/clients/clientZustandStore'
import moment from 'moment'
import { getPortalsInfoByClientId } from 'services/portalsAndProjects.service'
import { GlobalPortal, LastUpdate } from 'services/Client/client.models'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import { useIntl } from 'react-intl'
import axios, { AxiosError } from 'axios'

export default function PortalList() {
  const intl = useIntl()
  const clientId = useClientId()
  const [portal, setPortal] = useState<GlobalPortal[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    fetchPortals()
  }, [])

  const fetchPortals = () => {
    if (!clientId) {
      return
    }
    setIsLoading(true)

    getPortalsInfoByClientId(clientId)
      .then((receivedPortal: GlobalPortal) => {
        setPortal([receivedPortal])
      })
      .catch((e: AxiosError | Error) => {
        LoggerHelper(e)
        if (axios.isAxiosError(e) && e.response && e.response.status === 404) {
          Message.warning(intl.$t({ id: 'api_portal_does_not_exist' }), 5)
        } else {
          Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }))
        }
      })
      .finally(() => setIsLoading(false))
  }

  const columns = [
    {
      title: translate('portal_name'),
      dataIndex: 'portalName',
      key: 'portalName',
      width: '19%'
    },
    {
      title: translate('questionnaire_name'),
      dataIndex: 'questionnaireName',
      key: 'questionnaireName',
      width: '38%',
      render: (questionnaireName: string | null) => questionnaireName ?? '-'
    },
    {
      title: translate('table_column_creation_date'),
      dataIndex: 'portalCreated',
      key: 'portalCreated',
      width: '19%',
      render: (portalCreated: LastUpdate | null) => (portalCreated !== null ? moment.utc(portalCreated.date).local().format('DD.MM.YYYY HH:mm') : '-')
    },
    {
      title: translate('table_column_created_by'),
      dataIndex: 'createdBy',
      key: 'createdBy',
      width: '24%',
      render: (createdBy: LastUpdate | null) => (createdBy !== null ? `${createdBy.user.firstName} ${createdBy.user.lastName}` : '-')
    }
  ]

  return (
    <Table
      dataSource={portal}
      pagination={false}
      columns={columns}
      rowKey='portalName'
      loading={isLoading}
      locale={{
        emptyText: <TableEmptyState />
      }}
      data-testid='portals_table'
      id='ClientTable'
    />
  )
}
