import { Tooltip } from '@bdo/kitchensink'
import translate from 'i18n/translate'
import useClientStore, { useApplicationIdByName } from 'features/clients/clientZustandStore'
import { useNavigate } from 'react-router-dom'
import IconButton from 'ui-components/IconButton/IconButton'
import { isProcessing } from 'services/Client/clientProcessedQuestionnaireStatus.service'

type Props = {
  instanceId: string
  questionnaireId: string
  appName: string
}

export default function RedirectButton(props: Readonly<Props>) {
  const { instanceId, questionnaireId, appName } = props
  const clientId = useClientStore((state) => state.client.id)
  const appId = useApplicationIdByName(appName)
  const navigate = useNavigate()

  const handleOnClick = () => {
    navigate(`../questionnaires/${questionnaireId}/application/${appId}/instance/${instanceId}/fill`, { state: { key: 'portal_and_projects' } })
  }

  return (
    <Tooltip title={translate('button_edit')} mouseEnterDelay={0.5}>
      <p>
        <IconButton onClick={handleOnClick} type='Edit' disabled={isProcessing(clientId, appId, instanceId)} />
      </p>
    </Tooltip>
  )
}
