import clsx from 'clsx'
import { AnswerQuestionnaireStatus } from 'services/QuestionsAnswers/QuestionsAnswers.model'
import useClientStore, { useApplicationName, useClientId, useLastAnsweredQuestionnaireData, usePublishedQuestionnaireData } from 'features/clients/clientZustandStore'
import { Apps } from 'services/Questionnaire/questionnaire.models'
import PageNotification from 'ui-components/PageNotification/PageNotification'
import { useIntl } from 'react-intl'
import { useEffect, useState } from 'react'
import { getPortalsInfoByClientId } from 'services/portalsAndProjects.service'
import { GlobalPortal } from 'services/Client/client.models'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import { AxiosError } from 'axios'
import { appsConfiguration } from 'configuration/applicationsConfiguration'
import { globalPortalInfo } from 'app.config'
import styles from './TabServicesApps.module.scss'
import PublishedTwinfield from './PublishedTwinfield'
import PublishedCreatePortal from './PublishedCreatePortal'
import PublishedCreateProject from './PublishedCreateProject'

type Props = {
  appId: number
}

export default function AppWrapper(props: Readonly<Props>) {
  const { appId } = props
  const intl = useIntl()
  const applicationName = useApplicationName(appId)
  const lastAnsweredQuestionnaireData = useLastAnsweredQuestionnaireData(appId)
  const publishedQuestionnaireData = usePublishedQuestionnaireData(appId)
  const appIdsWhereAnswersForImportAreAvailable = useClientStore((state) => state.appIdsWhereAnswersForImportAreAvailable)
  const isNewQuestionnairePublished = publishedQuestionnaireData?.status === AnswerQuestionnaireStatus.NotStarted
  const isLastAnsweredQuestionnaireAvailable = lastAnsweredQuestionnaireData !== null
  const clientId = useClientId()
  const [isPortalInfoLoading, setIsPortalInfoLoading] = useState<boolean>(false)
  const [isProjectInfoLoading, setIsProjectInfoLoading] = useState<boolean>(false)
  const [portal, setPortal] = useState<GlobalPortal>()
  const { isAnswersImportEnabled } = appsConfiguration[applicationName]
  const isGlobalPortalDisabled = globalPortalInfo.isDisabled

  useEffect(() => {
    if (!clientId || isGlobalPortalDisabled) {
      return
    }
    setIsPortalInfoLoading(true)
    setIsProjectInfoLoading(true)
    getPortalsInfoByClientId(clientId)
      .then((data: GlobalPortal) => {
        setPortal(data)
      })
      .catch((error: AxiosError | Error) => {
        setIsProjectInfoLoading(false)
        LoggerHelper(error)
      })
      .finally(() => {
        setIsPortalInfoLoading(false)
      })
  }, [clientId])

  return (
    <>
      {isNewQuestionnairePublished && isLastAnsweredQuestionnaireAvailable && isAnswersImportEnabled && (
        <PageNotification
          notification={
            appIdsWhereAnswersForImportAreAvailable.includes(appId)
              ? intl.$t({ id: 'user_message_new_questionnaire_published' })
              : intl.$t({ id: 'user_message_new_questionnaire_published_no_relations' })
          }
          warningNotification={!appIdsWhereAnswersForImportAreAvailable.includes(appId)}
          infoNotification={appIdsWhereAnswersForImportAreAvailable.includes(appId)}
        />
      )}

      <div className={clsx(styles.singleAppWrapper)}>
        {applicationName === Apps.twinfield && <PublishedTwinfield appId={appId} />}
        {applicationName === Apps.createPortal && <PublishedCreatePortal appId={appId} portal={portal} isPortalInfoLoading={isPortalInfoLoading} />}
        {applicationName === Apps.createProject && (
          <PublishedCreateProject
            appId={appId}
            portal={portal}
            isPortalInfoLoading={isPortalInfoLoading}
            isProjectLoading={isProjectInfoLoading}
            setIsProjectLoading={setIsProjectInfoLoading}
          />
        )}
      </div>
    </>
  )
}
