import { Message, Tooltip } from '@bdo/kitchensink'
import GlobalPortalLogo from 'assets/images/global-portal-logo.svg'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import { AnswerQuestionnaireStatus } from 'services/QuestionsAnswers/QuestionsAnswers.model'
import useClientStore, { useApplicationName, useClientId, useLastAnsweredQuestionnaireData, usePublishedQuestionnaireData } from 'features/clients/clientZustandStore'
import IconButton from 'ui-components/IconButton/IconButton'
import { useEffect, useState } from 'react'
import { getAnswersForClient } from 'services/QuestionsAnswers/QuestionAnswer.service'
import { useErrorBoundary } from 'react-error-boundary'
import { useIntl } from 'react-intl'
import { isProcessing } from 'services/Client/clientProcessedQuestionnaireStatus.service'
import { GlobalPortal } from 'services/Client/client.models'
import { appsConfiguration } from 'configuration/applicationsConfiguration'
import translate from 'i18n/translate'
import styles from './PublishedApp.module.scss'
import ProjectInfo from './ProjectInfo'
import AnswersStatusBadge from '../TabPortalProjects/AnswersStatusBadge'
import LastAnsweredQuestionnaire from './LastAnsweredQuestionnaire'

export enum PortalStatus {
  Active = 'Active',
  Processing = 'Processing'
}

type Props = {
  appId: number
  portal: GlobalPortal | undefined
  isPortalInfoLoading: boolean
  isProjectLoading: boolean
  setIsProjectLoading: (value: boolean) => void
}

function PublishedCreateProject(props: Readonly<Props>) {
  const { appId, portal, isPortalInfoLoading, isProjectLoading, setIsProjectLoading } = props
  const clientId = useClientId()
  const navigate = useNavigate()
  const publishedQuestionnaireData = usePublishedQuestionnaireData(appId)
  const [isPortalCreated, setIsPortalCreated] = useState(false)
  const lastAnsweredQuestionnaireData = useLastAnsweredQuestionnaireData(appId)
  const publishedQuestionnaireStatus = publishedQuestionnaireData ? publishedQuestionnaireData.status : AnswerQuestionnaireStatus.NotAvailable
  const publishedQuestionnaireId = publishedQuestionnaireData ? publishedQuestionnaireData.questionnaireId : ''
  const publishedQuestionnaireName = publishedQuestionnaireData ? publishedQuestionnaireData.questionnaireName : ''
  const instanceId = publishedQuestionnaireData?.instanceId
  const applicationName = useApplicationName(appId)
  const removeAppIdsThatHaveAnswersAvailable: (appId: number) => void = useClientStore((state) => state.removeAppIdsThatHaveAnswersAvailable)
  const addAppIdsThatHaveAnswersAvailable: (appId: number) => void = useClientStore((state) => state.addAppIdsThatHaveAnswersAvailable)
  const { showBoundary } = useErrorBoundary()
  const intl = useIntl()
  const { isAnswersImportEnabled } = appsConfiguration[applicationName]

  const handleClick = () => {
    navigate(`../questionnaires/${publishedQuestionnaireId}/application/${appId}/instance/${instanceId}/fill`, { state: { key: 'services_apps' } })
  }

  useEffect(() => {
    if (!instanceId || !clientId || !isAnswersImportEnabled) {
      return
    }
    getAnswersForClient(clientId, instanceId)
      .then((answers) => {
        if (answers.length > 0 && !answers[0].id) {
          addAppIdsThatHaveAnswersAvailable(appId)
        } else if ((answers.length > 0 && answers[0].id) || answers.length === 0) {
          removeAppIdsThatHaveAnswersAvailable(appId)
        }
      })
      .catch((e) => {
        showBoundary(e)
      })
  }, [publishedQuestionnaireData])

  useEffect(() => {
    if (!clientId || !portal) {
      setIsProjectLoading(false)
      return
    }

    const isPortalStatusActive = portal.portalStatus === PortalStatus.Active
    const isPortalStatusProcessing = portal.portalStatus === PortalStatus.Processing

    if (isPortalStatusProcessing) {
      Message.warning(intl.$t({ id: 'portal_processed' }), 10)
      setIsProjectLoading(false)
    }

    setIsPortalCreated(isPortalStatusActive)
  }, [portal])

  return (
    <>
      <div className={clsx(styles.publishedQuestionnaire, 'flex', 'alignItems')}>
        <div className={clsx(styles.publishedQuestionnaireWrapper, 'flex', 'alignItems')}>
          <img src={GlobalPortalLogo} alt='' />
          <div className={styles.publishedQuestionnaireTitle}>
            <h4 className='flex alignItems'>
              {publishedQuestionnaireStatus !== AnswerQuestionnaireStatus.NotAvailable ? publishedQuestionnaireName : translate('application_Project')}
            </h4>
          </div>
        </div>
        <div className={clsx(styles.statusBadge)}>
          <AnswersStatusBadge questionnaireStatus={publishedQuestionnaireStatus} customClassName={styles.status} applicationName={applicationName} />
        </div>
        <Tooltip title={!isPortalCreated ? intl.$t({ id: 'portal_not_created' }) : ''} mouseEnterDelay={0.3} placement='topRight'>
          <div className={styles.editButton}>
            <IconButton
              type={
                publishedQuestionnaireStatus === AnswerQuestionnaireStatus.NotStarted || publishedQuestionnaireStatus === AnswerQuestionnaireStatus.NotAvailable
                  ? 'CirclePlus'
                  : 'Edit'
              }
              toolTipTitle={publishedQuestionnaireStatus === AnswerQuestionnaireStatus.NotStarted ? intl.$t({ id: 'project_create' }) : intl.$t({ id: 'button_edit' })}
              testId='editIcon'
              onClick={handleClick}
              loading={isPortalInfoLoading}
              disabled={
                (isProcessing(clientId, appId, instanceId) && publishedQuestionnaireStatus === AnswerQuestionnaireStatus.Processing) ||
                publishedQuestionnaireStatus === AnswerQuestionnaireStatus.NotAvailable ||
                !isPortalCreated
              }
            />
          </div>
        </Tooltip>
      </div>
      <div className='flex'>
        <ProjectInfo isPortalCreated={isPortalCreated} isProjectLoading={isProjectLoading} setIsProjectLoading={setIsProjectLoading} />

        {lastAnsweredQuestionnaireData &&
          publishedQuestionnaireStatus !== AnswerQuestionnaireStatus.Completed &&
          lastAnsweredQuestionnaireData.status !== AnswerQuestionnaireStatus.NotStarted && <LastAnsweredQuestionnaire appId={appId} />}
      </div>
    </>
  )
}

export default PublishedCreateProject
