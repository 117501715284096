import TwinfieldLogo from 'assets/images/twinfield_logo.png'
import translate from 'i18n/translate'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
import { AnswerQuestionnaireStatus } from 'services/QuestionsAnswers/QuestionsAnswers.model'
import useClientStore, { useApplicationName, useClientId, useLastAnsweredQuestionnaireData, usePublishedQuestionnaireData } from 'features/clients/clientZustandStore'
import IconButton from 'ui-components/IconButton/IconButton'
import { isProcessing } from 'services/Client/clientProcessedQuestionnaireStatus.service'
import { useEffect } from 'react'
import { useErrorBoundary } from 'react-error-boundary'
import { getAnswersForClient } from 'services/QuestionsAnswers/QuestionAnswer.service'
import { useIntl } from 'react-intl'
import { appsConfiguration } from 'configuration/applicationsConfiguration'
import styles from './PublishedApp.module.scss'
import AnswersStatusBadge from '../TabPortalProjects/AnswersStatusBadge'
import TwinfieldInfo from './TwinfieldInfo'
import LastAnsweredQuestionnaire from './LastAnsweredQuestionnaire'

type Props = {
  appId: number
}

function PublishedTwinfield(props: Readonly<Props>) {
  const { appId } = props
  const navigate = useNavigate()
  const clientId = useClientId()
  const publishedQuestionnaireData = usePublishedQuestionnaireData(appId)
  const lastAnsweredQuestionnaireData = useLastAnsweredQuestionnaireData(appId)
  const publishedQuestionnaireStatus = publishedQuestionnaireData ? publishedQuestionnaireData.status : AnswerQuestionnaireStatus.NotAvailable
  const publishedQuestionnaireName = publishedQuestionnaireData ? publishedQuestionnaireData.questionnaireName : ''
  const publishedQuestionnaireId = publishedQuestionnaireData ? publishedQuestionnaireData.questionnaireId : ''
  const instanceId = publishedQuestionnaireData?.instanceId
  const applicationName = useApplicationName(appId)
  const { showBoundary } = useErrorBoundary()
  const removeAppIdsThatHaveAnswersAvailable: (appId: number) => void = useClientStore((state) => state.removeAppIdsThatHaveAnswersAvailable)
  const addAppIdsThatHaveAnswersAvailable: (appId: number) => void = useClientStore((state) => state.addAppIdsThatHaveAnswersAvailable)
  const intl = useIntl()
  const { isAnswersImportEnabled } = appsConfiguration[applicationName]

  useEffect(() => {
    if (!instanceId || !clientId || !isAnswersImportEnabled) {
      return
    }
    getAnswersForClient(clientId, instanceId)
      .then((answers) => {
        if (answers.length > 0 && !answers[0].id) {
          addAppIdsThatHaveAnswersAvailable(appId)
        } else if ((answers.length > 0 && answers[0].id) || answers.length === 0) {
          removeAppIdsThatHaveAnswersAvailable(appId)
        }
      })
      .catch((e) => {
        showBoundary(e)
      })
  }, [publishedQuestionnaireData])

  const handleClick = () => {
    navigate(`../questionnaires/${publishedQuestionnaireId}/application/${appId}/instance/${instanceId}/fill`, { state: { key: 'services_apps' } })
  }

  return (
    <>
      <div className={clsx(styles.publishedQuestionnaire, 'flex', 'alignItems')}>
        <div className={clsx(styles.publishedQuestionnaireWrapper, 'flex', 'alignItems')}>
          <img src={TwinfieldLogo} alt='' />
          <div className={styles.publishedQuestionnaireTitle}>
            <h4 className='flex alignItems'>
              {publishedQuestionnaireStatus !== AnswerQuestionnaireStatus.NotAvailable ? publishedQuestionnaireName : translate('app_twinfield_general_ledger')}
            </h4>
          </div>
        </div>
        <div className={clsx(styles.statusBadge)}>
          <AnswersStatusBadge questionnaireStatus={publishedQuestionnaireStatus} customClassName={styles.status} applicationName={applicationName} />
        </div>
        <div className={styles.editButton}>
          <IconButton
            type='Edit'
            toolTipTitle={intl.$t({ id: 'button_edit' })}
            testId='editIcon'
            onClick={handleClick}
            disabled={
              (isProcessing(clientId, appId, instanceId) && publishedQuestionnaireStatus === AnswerQuestionnaireStatus.Processing) ||
              publishedQuestionnaireStatus === AnswerQuestionnaireStatus.NotAvailable
            }
          />
        </div>
      </div>
      <div className='flex'>
        {publishedQuestionnaireStatus !== AnswerQuestionnaireStatus.NotStarted &&
          publishedQuestionnaireStatus !== AnswerQuestionnaireStatus.NotAvailable &&
          publishedQuestionnaireStatus !== AnswerQuestionnaireStatus.Processing && <TwinfieldInfo appId={appId} />}
        {lastAnsweredQuestionnaireData &&
          publishedQuestionnaireStatus !== AnswerQuestionnaireStatus.Completed &&
          lastAnsweredQuestionnaireData.status !== AnswerQuestionnaireStatus.NotStarted && <LastAnsweredQuestionnaire appId={appId} />}
      </div>
    </>
  )
}

export default PublishedTwinfield
