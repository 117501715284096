import translate from 'i18n/translate'
import { useIntl } from 'react-intl'
import clsx from 'clsx'
import { Form, Select, Button, Message, Tooltip } from '@bdo/kitchensink'
import { toLimitString } from 'utils/StringHelper/string-helper'
import { useEffect, useState } from 'react'
import { getChildQuestions, getGroups, getQuestionnaires, getQuestions } from 'services/Questionnaire/questionnaire.service'
import { Apps, QuestionnaireStatuses, QuestionTypes } from 'services/Questionnaire/questionnaire.models'
import { useAccountStore } from 'features/generalLedger/accountStore/accountStore'
import { useNavigate, useParams } from 'react-router-dom'
import usePrompt from 'hooks/usePromptHook'
import { getAllTemplates } from 'services/templates.service'
import { addAccountDescription, deleteAccountDescription, AccountDescription, updateAccountDescription, getAccountDescriptions } from 'services/accountDescription.service'
import {
  useAccountDescriptionStore,
  useChildQuestions,
  useQuestions,
  useSelectedQuestionId,
  useSelectedChildQuestionId,
  useTemplates,
  useGroups,
  useIsDirty,
  useQuestionnaires,
  useSelectedGroupId,
  useSelectedQuestionnaireId,
  useSelectedTemplateId
} from 'features/generalLedger/accountDescriptionStore/accountDescriptionStore'
import Loader from 'ui-components/Loader/Loader'
import { LoggerHelper } from 'utils/ErrorHelper/ErrorHelper'
import Icon from 'ui-components/Icon/Icon'
import styles from './Description.module.scss'

function Description() {
  const [form] = Form.useForm()
  const intl = useIntl()

  const { Option } = Select
  const { id } = useParams()

  if (!id) {
    throw new Error(`Account id was not found`)
  }

  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [isQuestionnaireListLoading, setIsQuestionnaireListLoading] = useState<boolean>(false)
  const [isGroupListLoading, setIsGroupListLoading] = useState<boolean>(false)
  const [isQuestionListLoading, setIsQuestionListLoading] = useState<boolean>(false)
  const [isTemplateListLoading, setIsTemplateListLoading] = useState<boolean>(false)
  const accountId = Number.parseInt(id, 10)
  const navigate = useNavigate()
  const { accountInfo } = useAccountStore()
  const [canSave, setCanSave] = useState<boolean>(false)

  const {
    setQuestionnaires,
    setGroups,
    setQuestions,
    setChildQuestions,
    selectQuestionnaire,
    selectGroup,
    selectQuestion,
    setTemplates,
    selectTemplate,
    selectChildQuestion,
    allAccountDescriptions,
    setAllAccountDescriptions,
    addDescription,
    updateDescription,
    deleteDescription,
    setCurrentDescription,
    currentAccountDescription,
    cleanup
  } = useAccountDescriptionStore()

  const parentQuestions = useQuestions()
  const selectedQuestionId = useSelectedQuestionId()
  const parentQuestion = parentQuestions.find((q) => q.id === selectedQuestionId)
  const childQuestions = useChildQuestions()
  const selectedChildQuestionId = useSelectedChildQuestionId()
  const templates = useTemplates()
  const groups = useGroups()
  const isDirty = useIsDirty()
  const questionnaires = useQuestionnaires()
  const selectedGroupId = useSelectedGroupId()
  const selectedQuestionnaireId = useSelectedQuestionnaireId()
  const selectedTemplateId = useSelectedTemplateId()

  usePrompt(intl.$t({ id: 'user_message_unsaved_changes' }), isDirty)

  useEffect(() => {
    if (accountInfo.alwaysOn) {
      navigate(`/generalLedger/${accountId}/account`)
    }
  }, [accountInfo])

  useEffect(() => {
    const getQuestionnairesPromise = getQuestionnaires(undefined, [QuestionnaireStatuses.Draft, QuestionnaireStatuses.Published], Apps.twinfield)
    const getAccountDescriptionsPromise = getAccountDescriptions(accountId)

    setIsQuestionnaireListLoading(true)
    Promise.all([getQuestionnairesPromise, getAccountDescriptionsPromise])
      .then(([QuestionnairesData, accountDescriptions]) => {
        setQuestionnaires(QuestionnairesData)
        const firstQuestionnaire = QuestionnairesData[0]
        if (firstQuestionnaire) {
          selectQuestionnaire(firstQuestionnaire.id)
        }
        setAllAccountDescriptions(accountDescriptions)
      })
      .catch((error) => {
        Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }))
        LoggerHelper(error)
      })
      .finally(() => setIsQuestionnaireListLoading(false))

    setIsTemplateListLoading(true)
    getAllTemplates()
      .then((data) => {
        setTemplates(data)
      })
      .catch((error) => {
        Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }))
        LoggerHelper(error)
      })
      .finally(() => setIsTemplateListLoading(false))

    return cleanup
  }, [])

  const loadDescription = (description: AccountDescription | undefined) => {
    if (description) {
      setCurrentDescription(description)
      selectQuestionnaire(description.questionnaireId)
      selectGroup(description.groupId)
      selectTemplate(description.descriptionTemplateId)
    }
  }

  useEffect(() => {
    const foundQuestion = parentQuestions.find((q) => q.id === currentAccountDescription.questionId)
    if (foundQuestion) {
      selectQuestion(currentAccountDescription.questionId)
    } else if (selectedGroupId) {
      if (currentAccountDescription.questionId === '') {
        selectQuestion(undefined)
        selectChildQuestion(undefined)
      } else {
        selectQuestion(currentAccountDescription.parentQuestionId)
        selectChildQuestion(currentAccountDescription.questionId)
      }
    }
  }, [parentQuestions])

  useEffect(() => {
    setCurrentDescription({ accountId: 0, groupId: '', questionId: '', questionnaireId: '', descriptionTemplateId: '', parentQuestionId: '' })
    setChildQuestions([])

    const accountDescription = allAccountDescriptions.find((x) => x.questionnaireId === selectedQuestionnaireId)
    loadDescription(accountDescription)

    if (selectedQuestionnaireId) {
      form.setFields([{ name: 'questionnaire', value: selectedQuestionnaireId }])
      setIsGroupListLoading(true)
      getGroups(selectedQuestionnaireId)
        .then((data) => {
          setGroups(data)
        })
        .catch((error) => {
          Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }))
          LoggerHelper(error)
        })
        .finally(() => setIsGroupListLoading(false))
    }
  }, [selectedQuestionnaireId])

  useEffect(() => {
    if (groups.length !== 0) form.setFields([{ name: 'questionGroup', value: selectedGroupId }])
  }, [groups, selectedGroupId])

  useEffect(() => {
    setQuestions([])
    if (selectedGroupId) {
      setIsQuestionListLoading(true)
      getQuestions(selectedGroupId, [QuestionTypes.Text, QuestionTypes.Multiplied])
        .then((data) => {
          setQuestions(data)
        })
        .catch((error) => {
          Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }))
          LoggerHelper(error)
        })
        .finally(() => setIsQuestionListLoading(false))
    }
  }, [selectedGroupId])

  useEffect(() => {
    if (selectedChildQuestionId) {
      selectChildQuestion(undefined)
    }

    if (selectedQuestionId && parentQuestion?.type === QuestionTypes.Multiplied) {
      getChildQuestions(selectedQuestionId)
        .then((selectedParentQuestion) => {
          const questionsWithOptions = selectedParentQuestion.generatedQuestions.filter((q) => q.type === QuestionTypes.Text)
          setChildQuestions(questionsWithOptions)
        })
        .catch((error) => {
          Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }))
          LoggerHelper(error)
        })
    }
  }, [selectedQuestionId])

  useEffect(() => {
    form.setFields([
      { name: 'question', value: selectedQuestionId },
      { name: 'childQuestion', value: undefined }
    ])
    if (selectedQuestionId && parentQuestion?.type === QuestionTypes.Multiplied) {
      getChildQuestions(selectedQuestionId)
        .then((selectedParentQuestion) => {
          const questionsWithOptions = selectedParentQuestion.generatedQuestions.filter((q) => q.type === QuestionTypes.Text)
          setChildQuestions(questionsWithOptions)
        })
        .catch((error) => {
          Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }))
          LoggerHelper(error)
        })
    }
  }, [parentQuestion])

  useEffect(() => {
    form.setFields([{ name: 'childQuestion', value: selectedChildQuestionId }])
  }, [selectedChildQuestionId])

  useEffect(() => {
    form.setFields([{ name: 'template', value: selectedTemplateId }])
  }, [selectedTemplateId])

  const onSubmit = () => {
    if (!selectedQuestionnaireId) {
      return
    }

    setIsSaving(true)

    if (!selectedGroupId || !selectedQuestionId || !selectedTemplateId) {
      deleteAccountDescription(accountId, selectedQuestionnaireId)
        .then(() => {
          deleteDescription(accountId, selectedQuestionnaireId)
          setCurrentDescription({ accountId: 0, groupId: '', questionId: '', questionnaireId: '', descriptionTemplateId: '' })
          Message.success(intl.$t({ id: 'user_message_description_removed' }))
        })
        .catch((error) => {
          Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }))
          LoggerHelper(error)
        })
        .finally(() => setIsSaving(false))
    } else {
      const existingAccountDescription = allAccountDescriptions.find((x) => x.questionnaireId === selectedQuestionnaireId)

      const descriptionToSave: AccountDescription = {
        id: existingAccountDescription?.id,
        accountId,
        questionnaireId: selectedQuestionnaireId,
        groupId: selectedGroupId,
        questionId: selectedChildQuestionId ?? selectedQuestionId,
        descriptionTemplateId: selectedTemplateId,
        parentQuestionId: selectedQuestionId || undefined
      }
      if (existingAccountDescription) {
        updateAccountDescription(descriptionToSave)
          .then((description) => {
            updateDescription(description)
            setCurrentDescription(description)
            Message.success(intl.$t({ id: 'user_message_description_saved' }))
          })
          .catch((error) => {
            Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }))
            LoggerHelper(error)
          })
          .finally(() => setIsSaving(false))
      } else {
        addAccountDescription(descriptionToSave)
          .then((description) => {
            addDescription(description)
            setCurrentDescription(description)
            Message.success(intl.$t({ id: 'user_message_description_saved' }))
          })
          .catch((error) => {
            Message.error(intl.$t({ id: 'message_error_sth_went_wrong' }))
            LoggerHelper(error)
          })
          .finally(() => setIsSaving(false))
      }
    }
  }

  const onClearBtnClick = () => {
    selectGroup(undefined)
    selectQuestion(undefined)
    selectTemplate(undefined)
    selectChildQuestion(undefined)
    setChildQuestions([])
  }

  useEffect(() => {
    const dirtyAndFilled =
      isDirty && selectedTemplateId !== undefined && selectedQuestionId !== undefined && parentQuestion?.type === QuestionTypes.Text && selectedGroupId !== undefined
    const dirtyAndFilledWithChildQuestion =
      isDirty &&
      selectedTemplateId !== undefined &&
      selectedQuestionId !== undefined &&
      parentQuestion?.type === QuestionTypes.Multiplied &&
      selectedChildQuestionId !== undefined &&
      selectedGroupId !== undefined

    const clearedDescription = isDirty && selectedGroupId === undefined && selectedQuestionId === undefined && selectedTemplateId === undefined
    const canSaveDescription = dirtyAndFilled || clearedDescription || dirtyAndFilledWithChildQuestion

    setCanSave(canSaveDescription)
  }, [isDirty, isSaving, selectedGroupId, selectedQuestionId, selectedTemplateId, selectedChildQuestionId])

  const onClearChildQuestionSelectField = () => {
    selectChildQuestion(undefined)
  }

  return (
    <>
      <Form form={form} layout='vertical' className={clsx(styles.descriptionForm)} onFinish={onSubmit}>
        <div className={clsx(styles.descriptionWrapper, 'card')}>
          <h3>{translate('account_customer_description')}</h3>

          <div className={clsx(styles.wrapper, 'flex')}>
            <Form.Item name='questionnaire' label={translate('questionnaire')} className={styles.selectField}>
              <Select
                onChange={selectQuestionnaire}
                placeholder={intl.$t({ id: 'questionnaire_select' })}
                loading={isQuestionnaireListLoading}
                data-testid='questionnaire_select'
                showSearch
                filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase().includes(input.toLowerCase())}
              >
                {questionnaires.map((q) => (
                  <Option value={q.id} label={q.name} key={q.id} className='flagInDropdown'>
                    <Tooltip title={q.name} mouseEnterDelay={1}>
                      <span>{toLimitString(`${q.name} (${intl.$t({ id: `questionnaire_${q.status.toLowerCase()}` })}) `, 60)}</span>
                    </Tooltip>
                    {allAccountDescriptions.findIndex((x) => x.questionnaireId === q.id) !== -1 ? (
                      <span className={clsx(styles.descriptionFlag, 'flag')}>{translate('account_description')}</span>
                    ) : null}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <div className={styles.clearButton}>
              <Button type='default' onClick={onClearBtnClick}>
                {translate('button_clear_description')}
              </Button>
            </div>

            <Form.Item name='questionGroup' label={translate('question_group')} className={styles.selectField}>
              <Select
                onChange={selectGroup}
                placeholder={intl.$t({ id: 'question_group_select' })}
                loading={isGroupListLoading}
                data-testid='question_group_select'
                showSearch
                filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase().includes(input.toLowerCase())}
                options={groups.map((g) => ({ value: g.key, label: g.name }))}
              />
            </Form.Item>
            <Form.Item name='question' label={translate('question')} className={styles.selectField}>
              <Select
                onChange={selectQuestion}
                placeholder={intl.$t({ id: 'question_select' })}
                loading={isQuestionListLoading}
                data-testid='question_select'
                showSearch
                filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase().includes(input.toLowerCase())}
                options={parentQuestions.map((q) => ({ value: q.key, label: q.text }))}
              />
            </Form.Item>
            {parentQuestion?.type === QuestionTypes.Multiplied && parentQuestion.generatedQuestions.length === 0 && (
              <Form.Item
                name='childQuestion'
                label={translate('question_child')}
                rules={[
                  {
                    required: true,
                    message: translate('forms_required_field')
                  }
                ]}
                className={styles.selectField}
              >
                <Select
                  onChange={selectChildQuestion}
                  onClear={onClearChildQuestionSelectField}
                  placeholder={intl.$t({ id: 'question_select' })}
                  data-testid='generated_question_select'
                  loading={isQuestionListLoading}
                  allowClear
                  showSearch
                  filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={childQuestions.map((q) => ({ value: q.key, label: q.text }))}
                />
              </Form.Item>
            )}
            <Form.Item name='template' label={translate('description_template')} className={styles.selectField}>
              <Select
                onChange={selectTemplate}
                placeholder={intl.$t({ id: 'description_template_select' })}
                loading={isTemplateListLoading}
                data-testid='description_select'
                showSearch
                filterOption={(input, option) => (option?.label?.toString() ?? '').toLowerCase().includes(input.toLowerCase())}
                options={templates.map((t) => ({ value: t.id, label: t.name }))}
              />
            </Form.Item>
          </div>
          <div className={clsx(styles.saveButtonWrapper, 'flex', 'alignItems')}>
            <Button type='primary' className={styles.saveButton} onClick={form.submit} loading={isSaving} disabled={!isSaving && !canSave}>
              {translate('button_save')}
            </Button>
            {isDirty && (
              <span className={clsx(styles.unsavedChanges, 'flex', 'alignItems')}>
                <Icon type='Error' /> {translate('user_message_questionnaire_unsaved_changes')}
              </span>
            )}
          </div>
        </div>
      </Form>
      <Loader open={isSaving} text={intl.$t({ id: 'action_saving' })} />
    </>
  )
}

export default Description
