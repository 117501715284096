/* eslint-disable camelcase */
import { LOCALES } from '../locales'

export default {
  [LOCALES.DUTCH]: {
    account_number: 'Rekeningnummer',
    account_type: 'Type',
    account_type_info: 'Rekeningtype',
    account_description_en: 'Standaardrekening omschrijving EN',
    account_description_nl: 'Standaardrekening omschrijving NL',
    account_isActive: 'Altijd aanwezig',
    account_add: 'Rekening toevoegen',
    account_details: 'Rekeningdetails',
    account_condition: 'Conditie',
    account_description: 'Omschrijving',
    account_customer_description: 'Klant omschrijving',
    account_scheme: 'Rekeningschema',
    actions: 'Acties',
    action_saving: 'Opslaan...',
    action_publishing: 'Publiceren...',
    action_processing_questionnaire: 'Vragenlijst verwerken...',
    action_exporting: 'Exporteren...',
    action_prepare: 'Verzoek verzenden...',
    action_importing: 'Importeren ...',
    action_downloading: 'Bestand downloaden...',
    action_loading: 'Laden...',
    action_loading_answers: 'Antwoorden laden...',
    action_duplicating: 'Vragenlijst dupliceren...',
    app_name: 'Onboarding tool',
    app_version: 'Versie',
    app_welcome_message: 'Welkom bij de Onboarding Tool!',
    app_twinfield_general_ledger: 'Twinfield grootboek',
    application: 'Applicatie',
    application_: 'Applicatie naam',
    application_Twinfield: 'Twinfield',
    application_Portal: 'Portal aanmaken',
    application_Project: 'Project aanmaken',
    button_add: 'Toevoegen',
    button_archive: 'Archief',
    button_browse: 'Bladeren',
    button_cancel: 'Annuleren',
    button_clear: 'Wissen',
    button_close: 'Sluiten',
    button_combine: 'Combineren',
    button_confirm: 'Bevestigen',
    button_create: 'Aanmaken',
    button_done: 'Gereed',
    button_delete: 'Verwijderen',
    button_duplicate: 'Kopie',
    button_edit: 'Bewerken',
    button_view_answers: 'Bekijk antwoorden',
    button_goBack: 'Terug naar de vorige pagina',
    button_save: 'Opslaan',
    button_signIn: 'Inloggen',
    button_publish: 'Publiceren',
    button_apply_filters: 'Toepassen',
    button_clear_close: 'Wissen en sluiten',
    button_save_as_draft: 'Opslaan als concept',
    button_complete_Twinfield: 'Opslaan en exporteren',
    button_complete_Portal: 'Opslaan en portaal maken',
    button_complete_Project: 'Opslaan en project maken',
    button_update_Portal: 'Opslaan en portaal bijwerken',
    button_update_Project: 'Opslaan en project bijwerken',
    button_clear_condition: 'Verwijder conditie',
    button_clear_description: 'Verwijder klant omschrijving',
    button_reload_the_page: 'Herlaad pagina',
    description_templates: 'Klantbeschrijving templates',
    description_template: 'Klantbeschrijving template',
    description_template_add: 'Template toevoegen',
    description_template_edit: 'Template wijzigen',
    description_template_delete: 'Template verwijderen?',
    description_template_select: 'Selecteer klantbeschrijving template',
    download: 'Download',
    empty_page: 'Niets om weer te geven',
    empty_table: 'Geen gegevens',
    filter: 'Filter',
    filter_add: 'Conditie toevoegen',
    filter_condition: 'Conditie',
    filter_value: 'Waarde',
    filter_select_placeholder: 'Selecteer filter',
    filter_condition_placeholder: 'Selecteer conditie',
    filter_value_placeholder: 'Waarde invoeren',
    filter_value_yes: 'Ja',
    filter_value_no: 'Nee',
    forms_input_placeholder: 'Voer tekst in',
    forms_required_field: 'Dit veld is verplicht',
    forms_too_many_characters: 'Het antwoord bevat te veel tekens',
    forms_placeholder_no_characters: 'Vul tekst in tot {value} karakters',
    forms_placeholder_account_number: 'Voer een rekeningnummer van 7 cijfers in',
    form_placeholder_max_no_characters: 'Vul maximaal aantal karakters in',
    form_placeholder_no_brackets: 'Geef template naam in zonder blokhaken',
    form_placeholder_template_name: 'Geef template naam',
    form_placeholder_number: 'Vul nummer in',
    form_placeholder_date: 'Vul datum in',
    form_field_type: 'Soort vraag',
    form_max_no_characters: 'Maximaal aantal karakters',
    form_select: 'Kies optie',
    form_mandatory_field: 'Verplicht veld',
    form_set_to_true_by_default: 'Standaard <i>true</i> geselecteerd',
    form_type_text: 'Tekstveld',
    form_type_number: 'Cijferveld',
    form_type_date: 'Datumveld',
    form_type_dropdown: 'Dropdown-veld',
    form_type_radio: 'Radiobutton-veld',
    form_type_checkbox: 'Checkbox-veld',
    form_type_multiplied: 'Vragengenerator',
    form_type_boolean: 'True/false-veld',
    form_multiplication_number: 'Aantal gekopieerde vragen',
    form_generated_questions_template: 'Gegenereerde vragentemplate',
    form_set_default_option: 'Standaard optie instellen',
    form_set_none_option: 'Voeg de optie "Geen" toe',
    form_set_none_option_hint:
      'Indien aangevinkt, wordt de optie "Geen" toegevoegd. Met deze optie worden alle andere opties uitgeschakeld wanneer de gebruiker de vragenlijst beantwoordt',
    general_ui_page: 'pagina',
    general_ui_back_link: 'Teruggaan naar:',
    general_ui_draft: 'Concept',
    general_ui_status: 'Status',
    general_edited_by: 'Laatst gewijzigd:',
    general_completed_by: 'Afgerond door:',
    loading: 'Laden...',
    import: 'Importeren',
    import_drag_drop_files_here: 'Sleep bestanden hierheen',
    import_supported_files: 'Ondersteunde bestanden',
    import_accounts: 'Importeer rekeningen',
    import_templates: 'Sjablonen importeren',
    client_naturalPerson: 'Natural Person NL',
    client_details: 'Klantdetails',
    client_company: 'Company NL',
    client: 'Klant',
    clients: 'Klanten',
    client_relation_number: 'Relatienummer',
    client_name: 'Naam',
    client_address: 'Adres',
    client_postcode: 'Postcode',
    client_country: 'Land',
    client_legal_form: 'Rechtsvorm',
    client_sbi_main_activity: 'SBI hoofdactiviteit',
    client_description_templates: 'Klantbeschrijving templates',
    documents: 'Documenten',
    edit_name: 'Naam aanpassen',
    export: 'Export',
    start_import: 'Start importeren',
    import_success: 'Succes! Bestanden succesvol geïmporteerd',
    import_failed: 'Sorry, de bestanden zijn niet geïmporteerd. Controleer het document en probeer opnieuw',
    processing: 'Verwerking',
    general_ledger: 'Grootboek',
    column_one: 'Eén kolom',
    column_two: 'Twee kolommen',
    column_name: 'Naam kolom',
    column_name_edit: 'Naam kolom wijzigen',
    dashboard: 'Dashboard',
    dashboard_superuser: 'Dashboard SU',
    search_placeholder_clients: 'Zoek op Klantnaam of Relatienummer',
    search_placeholder_documents: 'Zoek op Bestandsnaam of Naam vragenlijst',
    service_outsourcing: 'Outsourcing',
    service_global_portal: 'Global Portal',
    services_and_apps: 'Diensten en applicaties',
    service: 'Dienst',
    status_InProgress: 'Bezig',
    status_NotStarted: 'Niet gestart',
    status_NotAvailable: 'Niet beschikbaar',
    status_Ready: 'Afgerond',
    status_Completed: 'Voltooid',
    status_Processing: 'Verwerken...',
    status_not_available_message: 'Er is geen gepubliceerde vragenlijst',
    status_in_progress_message: 'Er ontbreekt informatie in de vragenlijst',
    status_ready_Twinfield_message: 'De vragenlijst is klaar om geëxporteerd te worden',
    status_ready_Portal_message: 'De vragenlijst is gereed en het portaal kan worden aangemaakt/bijgewerkt',
    status_ready_Project_message: 'De vragenlijst is gereed en het project kan worden aangemaakt/bijgewerkt',
    status_completed_Twinfield_message: 'Vragenlijst is ingevuld en bestanden zijn geëxporteerd',
    status_completed_Portal_message: 'De vragenlijst is ingevuld en het portaal is gemaakt/bijgewerkt',
    status_completed_Project_message: 'De vragenlijst is ingevuld en het project is gemaakt/bijgewerkt',
    status_processing_message: 'Vragenlijst wordt verwerkt',
    table_column_modification_date: 'Laatst gewijzigd',
    table_column_modified_by: 'Gewijzigd door',
    table_column_creation_date: 'Einddatum',
    table_column_created_by: 'Afgemaakt door',
    table_column_status: 'Status',
    table_column_type: 'Type',
    table_column_name: 'Naam',
    table_column_file_name: 'Bestandsnaam',
    table_column_export_time: 'Laatste export',
    table_exports_switch: 'Laat vorige exports zien',
    table_column_questionnaire: 'Naam vragenlijst',
    nav_back: 'Vorige',
    nav_next: 'Volgende',
    operator_equal: 'is gelijk aan',
    operator_not_equal: 'is niet gelijk aan',
    operator_lower_than: 'is minder dan',
    operator_lower_equal: 'is minder dan of gelijk aan',
    operator_greater: 'is meer dan',
    operator_greater_equal: 'is meer dan of gelijk aan',
    operator_contains: 'bevat',
    operator_startswith: 'begint met',
    portal_and_projects: 'Portaal en projecten',
    portal_name: 'Portalnaam',
    project_name: 'Projectnaam',
    portal_create: 'Start aanmaken portaal',
    project_create: 'Start aanmaken project',
    created_portal_info: 'Portaalinformatie aangemaakt',
    portal_in_creation: 'Portal wordt aangemaakt',
    last_created_project_info: 'Laatst gemaakte projectinformatie',
    projects_view_all: 'Bekijk alle projecten',
    portal_created: 'Portaal aangemaakt:',
    portal_last_created: 'Portaal aangemaakte:',
    project_last_created: 'Laatst aangemaakte project:',
    preview_questionnaire: 'Voorbeeld vragenlijst',
    questionnaires: 'Vragenlijsten',
    questionnaire: 'Vragenlijst',
    questionnaire_select: 'Selecteer vragenlijst',
    questionnaire_create: 'Vragenlijst aanmaken',
    questionnaire_duplicate: 'Vragenlijst kopiëren',
    questionnaire_name: 'Naam vragenlijst',
    questionnaire_new_name: 'Nieuwe naam vragenlijst',
    questionnaire_name_edit: 'Naam vragenlijst aanpassen',
    questionnaire_draft: 'Concept',
    questionnaire_published: 'Gepubliceerd',
    questionnaire_archived: 'Gearchiveerd',
    questionnaire_archive: 'Archive questionnaire',
    questionnaire_show_archived: 'Laat gearchiveerde vragenlijsten zien',
    question_group: 'Vragengroep',
    question_group_select: 'Selecteer vragengroep',
    question_group_name: 'Naam vragengroep',
    question_group_add_new: 'Vragengroep toevoegen',
    question_group_edit_name: 'Groepsnaam aanpassen',
    question_group_delete: 'Groep verwijderen',
    question_options: 'Opties',
    question_more_options: 'Meer opties',
    question_linked_questions: 'Gekoppelde vragen toevoegen',
    linked_questions: 'Gekoppelde vragen',
    question_add_new: 'Vraag toevoegen',
    question_template_add_new: 'Vragentemplate toevoegen',
    question: 'Vraag',
    question_select: 'Selecteer vraag',
    question_text: 'Inhoud vraag',
    question_hint: 'Hint vraag',
    question_child: 'Gekoppelde vrag',
    no_questions: 'Geen vragen',
    sorting_direction_ascending: 'Klik om oplopend te sorteren',
    sorting_direction_descending: 'Klik om aflopend te sorteren',
    sorting_direction_cancel: 'Klik om sorteren te annuleren',
    user_message_when_delete_question: 'Vraag verwijderen?',
    user_message_when_delete_question_message: 'Weet u zeker dat u de vraag wil verwijderen? Dit kan niet worden teruggedraaid',
    user_message_when_delete_option_message: 'Weet u zeker dat u de optie wil verwijderen? Dit kan niet worden teruggedraaid',
    user_message_when_factor_decreasing_message:
      'Er is een reeks of gegenereerde vragen gekoppeld aan accountbeschrijvingen/voorwaarden. Door de factor te verlagen, worden er <b>{value} rekening(en)</b> gewijzigd.',
    user_message_when_delete_question_condition_description_warning_message:
      'Deze vraag is gekoppeld aan een omchrijving/conditie. Het verwijderen van de vraag zal de beschrijving/conditie voor <b>{connectionsCount} rekening(en)</b> verwijderen.',
    user_message_when_change_type_question_condition_description_warning_message:
      'Deze vraag is gekoppeld aan een omchrijving/conditie. Het veranderen van het vraagtype zal de beschrijving/conditie voor <b>{connectionsCount} rekening(en)</b> verwijderen.',
    user_message_when_delete_group_condition_description_warning_message:
      'Deze groep bevat vragen die aan een omschrijving/conditie zijn verbonden. Het verwijderen van de groep zal de beschrijving/conditie voor <b>{connectionsCount} rekening(en)</b> verwijderen.',
    user_message_when_delete_option_condition_warning_message:
      'Deze optie is gekoppeld aan een conditie. Het verwijderen van de optie zal de conditie in <b>{connectionsCount} rekening(en)</b> verwijderen.',
    user_message_when_publish_question: 'Vragenlijst publiceren?',
    user_message_when_publish_message:
      'Er is al een gepubliceerde versie van deze vragenlijst. Als een nieuwe versie gepubliceerd wordt, dan wordt de vorige gearchiveerd. Dit kan niet ongedaan gemaakt worden',
    user_message_when_delete_group: 'Vragengroep verwijderen?',
    user_message_when_delete_group_message: 'Weet u zeker dat u de vragengroep wil verwijderen? Dit kan niet worden teruggedraaid',
    user_message_combine_columns_question: 'Alle vragen in één kolom samenvoegen?',
    user_message_combine_columns_description: 'Wanneer u bevestigt, krijgt u één kolom met alle vragen samengevoegd. Dit kan niet worden teruggedraaid',
    user_message_unsaved_changes: 'Wijzigingen worden niet opgeslagen',
    user_message_questionnaire_unsaved_changes: 'Niet-opgeslagen wijzigingen',
    user_message_questionnaire_saved: 'Succes! De vragenlijst is opgeslagen',
    user_message_questionnaire_save_error: 'Sorry, de vragenlijst kan niet worden opgeslagen door een fout. Probeer het opnieuw',
    user_message_account_saved: 'Succes! De rekening is opgeslagen',
    user_message_account_save_error: 'Sorry, de rekening kan niet worden opgeslagen door een fout. Probeer het opnieuw',
    user_message_account_deleted: 'Succes! De rekening is verwijderd',
    user_message_account_deleted_error: 'Sorry, de rekening kan niet worden verwijderd door een fout. Probeer het opnieuw',
    user_message_change_question_type: 'Vraagtype veranderen?',
    user_message_change_question_type_info:
      'Deze vraag heeft opties en kan gekoppelde vragen hebben. Na bevestiging worden alle vragen verwijderd. Dit kan niet worden teruggedraaid',
    user_message_remove_question_with_child_questions: 'Deze vraag heeft gekoppelde vragen. Na bevestiging worden alle vragen verwijderd. ',
    user_message_delete_options_question: 'Optie verwijderen?',
    user_message_factor_number_decreasing_question: 'Aantal gekopieerde vragen verminderen?',
    user_message_delete_options_message: 'Er zijn gekoppelde vragen verbonden aan deze optie. Wanneer die verwijderd worden, verdwijnt ook de verbinding. ',
    user_message_change_type_from_question_generator: 'Deze vraag bevat vraagtemplates. Na bevestiging worden alle vragen verwijderd. Dit kan niet worden teruggedraaid',
    user_message_remove_question_generator: 'Deze vraag bevat vraagtemplates. Na bevestiging worden alle vragen verwijderd. ',
    user_message_missing_info: 'Ontbrekende informatie',
    user_message_when_archive_questionnaire_question: 'Vragenlijst archiveren?',
    user_message_when_archive_questionnaire_message: 'Weet u zeker dat u vragenlijst <b>{name}</b> wil archiveren? Dit kan niet worden teruggedraaid',
    user_message_when_archived_questionnaire_opened: 'Gearchiveerde vragenlijsten zijn read-only.',
    user_message_read_only_questionnaire: 'Dit is een read-only vragenlijst',
    user_message_when_published_questionnaire_opened:
      'Gepubliceerde vragenlijsten kunnen tot op zekere hoogte worden bewerkt. Het opslaan van gepubliceerde vragenlijsten is van invloed op alle klantvragenlijsten',
    user_message_when_delete_account_question: 'Rekening verwijderen?',
    user_message_when_delete_account_message: 'Weet u zeker dat u rekening <b>{number}</b> wil verwijderen? Dit kan niet worden teruggedraaid',
    user_message_7_digits: 'Voer 7 positieve cijfers in',
    user_message_integer: 'Ongeldige waarde',
    user_message_integer_string: 'Speciale tekens zijn niet toegestaan',
    user_message_number_field_range: 'Geef een cijfer in tussen 1 en {value}',
    user_message_field_empty: 'Veld mag niet leeg zijn',
    user_message_set_account_active_condition: 'Maak rekening actief als onderstaande opties geselecteerd zijn:',
    user_message_set_account_active_condition_with_generator: 'Maak rekening actief als getal uit onderstaand bereik is geselecteerd:',
    user_message_set_account_active_condition_with_generator_options: 'Mogelijke opties:',
    user_message_condition_saved: 'Conditie is opgeslagen',
    user_message_condition_removed: 'Conditie is opgeslagen',
    user_message_description_saved: 'Omschrijving is opgeslagen',
    user_message_description_removed: 'Omschrijving is opgeslagen',
    user_message_when_delete_template: 'Weet u zeker dat u template <b>[{name}]</b> wil verwijderen? Dit kan niet worden teruggedraaid',
    user_message_template_deleted: 'Succes! De template is verwijderd',
    user_message_template_deleted_error: 'Sorry, de template kan niet worden verwijderd door een fout. Probeer het opnieuw',
    user_message_when_add_template: 'Succes! Template is toegevoegd',
    user_message_when_update_template: 'Succes! De template is bijgewerkt',
    user_message_filters_updated: 'Succes! Rekeningenlijst is bijgewerkt',
    user_message_filters_no_results: 'Sorry, er zijn geen rekeningen gevonden. Probeer het opnieuw',
    user_message_documents_list_updated: 'De documentenlijst is bijgewerkt',
    user_message_documents_list_no_results: 'Sorry, er zijn geen documenten gevonden. Probeer het opnieuw',
    user_message_login_error: 'Login failed',
    user_message_at_least_one_template_question: 'Voeg tenminste één vragentemplate toe',
    user_message_files_exported: 'Bestanden zijn succesvol geëxporteerd',
    user_message_files_exported_and_saved: 'Bestanden zijn succesvol geëxporteerd',
    user_message_file_downloaded: 'Bestand is succesvol gedownload',
    user_message_file_download_failed: 'Bestand kon niet worden gedownload.',
    user_message_client_search: 'Succes! Klantenlijst is bijgewerkt',
    user_message_client_search_no_results: 'Sorry, er is geen klant gevonden. Probeer het opnieuw',
    user_message_new_questionnaire_published: 'Nieuwe vragenlijst is gepubliceerd. Relevante antwoorden van de vorige versie, worden geïmporteerd',
    user_message_new_questionnaire_published_no_relations: 'Nieuwe versie van de vragenlijst is gepubliceerd. Geen relevante antwoorden om te importeren',
    user_message_prev_answered_questionnaire: 'Eerder beantwoorde vragenlijst',
    user_message_answers_imported: 'Success! Antwoorden zijn geïmporteerd',
    user_message_answers_imported_error: 'Sorry, de antwoorden konden niet worden geïmporteerd',
    user_info_child_question_in_account_settings: 'Als dit veld leeggelaten wordt, wordt de conditie op de bovenliggende vraag toegepast.',
    view_results: 'Bekijk resultaten',
    message_error_page_not_found: 'Pagina niet gevonden',
    message_error_not_authorized: 'Niet geautoriseerd',
    message_error_sth_went_wrong: 'Er ging iets mis',
    message_error_group_name_exists: 'Groepsnaam bestaat al in deze vragenlijst',
    message_error_questionnaire_name_exists: 'De naam van de vragenlijst bestaat al',
    message_error_page_content_error: 'Ontbrekende gegevens, neem contact op met de beheerder',
    api_name_NotEmptyValidator: 'Naam cannot be empty',
    api_name_UniqueValueConflict: 'Naam bestaat al',
    api_account_number_UniqueValueConflict: 'Account bestaat al',
    api_template_UniqueValueConflict: 'Template naam bestaat al',
    api_DMS_UnauthorizedUser: 'U heeft geen rechten om deze klantdocumenten te zien. Neem contact op met Klanthub om toegang aan te vragen',
    api_DMS_MissingFolderStructure: 'Mappenstructuur in Sharepoint bestaat niet voor deze klant',
    api_template_import_NotAllowedCharacterInName: 'Niet-toegestane karakters gevonden. Aub alleen letters, cijfers en spaties gebruiken. Dit veld verbeteren alstublieft: {value}',
    api_template_import_InvalidImportFile_Ledgers: 'Kan bestand niet inladen, bevestig dat Excelbestand "Ledgers" heet',
    api_template_import_InvalidImportFile_Customerdescriptions: 'Kan bestand niet inladen, bevestig dat Excelbestand "Customerdescriptions" heet',
    api_GlobalPortal_ConfigurationService_WrongNumberOfOrganisations: 'Er kunnen geen projecten worden aangemaakt, omdat er nog geen portal is. Maak eerst een portal aan.',
    api_portal_does_not_exist: 'Er is geen portal gevonden voor die klant',
    api_portal_does_not_exist_ConfigurationService_InvalidId: 'Er is geen portal gevonden voor die klant',
    api_projects_do_not_exist: 'Er zijn geen projecten gevonden voor die klant',
    api_projects_do_not_exist_ConfigurationService_InvalidId: 'Er zijn geen projecten gevonden voor die klant',
    portal_not_created: 'Er kunnen geen projecten worden aangemaakt, omdat er nog geen portal is. Maak eerst een portal aan.',
    portal_processed: 'Het lijkt erop dat de portal nog niet klaar is, dus er kunnen geen projecten worden aangemaakt. Kom over een paar minuten terug en laad de pagina opnieuw',
    signalR_ExportFilesFinished_title: 'Bestanden zijn gëexporteerd naar DMS',
    signalR_ExportFilesFinished_message: 'Bestanden zijn succesvol geëxporteerd voor klant met relatienummer {clientId}',
    signalR_ExportFilesFailed_title: 'Probleem met exporteren naar DMS',
    signalR_ExportFilesFailed_message: 'Er ging iets mis',
    signalR_QuestionnairePublished_title: 'Nieuwe vragenlijst is gepubliceerd',
    signalR_QuestionnairePublished_message:
      'Deze vragenlijst is niet toegangelijk meer na het verlaten van deze pagina. Sla alstublieft uw antwoorden op, anders raken ze verloren.',
    signalR_PortalCreated_title: 'Portaal aanmaken',
    signalR_PortalNotCreated_title: 'Portaal is niet gemaakt',
    signalR_PortalCreated_message: 'Portaal is succesvol aangemaakt voor klant met relatienummer {clientId}',
    signalR_PortalUpdated_title: 'Portaal bijwerken',
    signalR_PortalUpdated_message: 'Portal is succesvol bijgewerkt voor klant met relatienummer {clientId}',
    signalR_PortalNotUpdated_title: 'Portaal is niet bijgewerkt',
    signalR_ProjectCreated_title: 'Project aanmaken',
    signalR_ProjectNotCreated_title: 'Project is niet gemaakt',
    signalR_ProjectCreated_message: 'Project is succesvol aangemaakt voor klant met relatienummer {clientId}',
    signalR_ProjectUpdated_title: 'Project bijwerken',
    signalR_ProjectUpdated_message: 'Project is succesvol bijgewerkt voor klant met relatienummer {clientId}',
    signalR_ProjectNotUpdated_title: 'Project is niet bijgewerkt',
    signalR_GlobalPortal_2504: 'Portal- of projectnaam is niet uniek of ongeldig. Wijzig de naam en probeer het opnieuw',
    signalR_GlobalPortal_2505: 'De portalnaam bevat een niet toegestaan speciaal teken. De enige tekens die kunnen worden gebruikt zijn ".", "@", "&", "(", ")"',
    signalR_GlobalPortal_2503: 'Portalnaam of BDO Admin is leeg. Geef de gegevens op en probeer het opnieuw',
    signalR_GlobalPortal_2507: 'Klantsite-eigenaar is leeg of ongeldig',
    signalR_GlobalPortal_3065: 'Het opgegeven e-mailadres van de site-eigenaar van de klant is niet geautoriseerd',
    signalR_GlobalPortal_3001: 'Main-BDO-contact-e-mailadres is ongeldig of wordt niet verstrekt',
    signalR_GlobalPortal_3011: 'Project bestaat al',
    signalR_GlobalPortal_3016: 'Het e-mailadres van de portal- of projecteigenaar is niet geregistreerd als beheerder',
    signalR_GlobalPortal_4204: 'Portaal niet gevonden',
    signalR_GlobalPortal_1002: 'Kan bestaande gebruiker niet toevoegen',
    signalR_CannotAddExistingUser: 'Gebruiker met e-mailadres {email} bestaat al en kan niet worden toegevoegd',
    signalR_GlobalPortal_DuplicatedEmailAddress: 'Gebruiker met e-mailadres {duplicatedEmailError} bestaat al in groepen {groups}',
    signalR_GlobalPortal_ClientPortalDuplicateExists: 'Portalnaam bestaat al binnen de organisatie. Gelieve naam te veranderen en opnieuw te proberen',
    signalR_2513: 'Er zijn dubbele e-mailadressen',
    signalR_default_error_message: 'Er is iets fout gegaan. Probeer het opnieuw'
  }
}
